;(function( $, window, document, undefined ) {

	"use strict";

	/**
	 *	contact form
	 */
	$.validator.setDefaults({
		rules: {
			email: {
				email: true,
			},
		},
		messages: {
			name:              '.',
			surname:           '.',
			street:            '.',
			plz:               '.',
			city:              '.',
			country:           '.',
			email:             '.',
			phone:             '.',
			organisation_size: '.',
			message:           '.',
			workshop1:         '.',
			workshop2:         '.',
			agb:               '.',
		},
		errorElement: "div",
		errorClass:   "error",
		validClass:   "success",
		success:      function(label) {
			label.addClass("success");
		},
		submitHandler: function() {
			sendMail();
			//form.submit();
		},
	});

	// validate the comment form when it is submitted
	$("#booking_form").validate();

	





	function sendMail() {
		event.preventDefault();

		// set data object
		var data = {
			mail_php:     $('#mail_php').val(),
			ajaxcall:     'true',
			salutation:   $('input[name=salutation]:checked', '#salutation').val(),
			title: 		  $("#title").val(),
			name:         $("#name").val(),
			surname: 	  $("#surname").val(),

			street: 	  $("#street").val(),
			plz: 		  $("#plz").val(),
			city: 		  $("#city").val(),
			country: 	  $("#country").val(),

			organisation: $("#organisation").val(),
			department:   $("#department").val(),
			role:         $("#role").val(),
			event_title:  $("#event_title").val(),

			//organisation_size: 	$("#organisation_size").val(),

			email:        $("#email").val(),
			phone: 	      $("#phone").val(),
			//workshop1: 	$("#workshop1").val(),
			//workshop2: 	$("#workshop2").val(),
			message:      $("#message").val(),
			//agb: 	$("#agb").val(),
			
		};


		
		$.ajax({
			type:       "POST",
			url:        data.mail_php,
			data:       data,
			dataType:   'json',
			beforeSend: function(xhr) {
				// console.debug(xhr);
				// console.debug('ajax before');
			},
			success: function(text, xhr) {
				//console.debug('ajax success');
				if (text.error_message !== true) {
					// shows success message
					$('.form_message.success').slideDown();
					$('.form').slideUp();
				} else {
					// something went wrong on the backend side
					//console.debug('ajax error');
				}
			},
			error: function(xhr) {
				// show ajax error
			},
		});
	}
	
})( jQuery, window, document );