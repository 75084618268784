;(function( $, window, document, undefined ) {

	"use strict";
/*
	$('#mobile-nav-btn').toggle(function() {
		//$('#main-nav').slideDown(250);
		console.debug( 'click' );
		$(this).toggleClass('active');
		$('#main-nav').toggleClass('active');
		return false;
	}, function() {
		//$('#main-nav').slideUp(250);
		$(this).toggleClass('active');
		$('#main-nav').toggleClass('active');
		return false;
	});



*/


	$('#mobile-nav-btn').on('click', function(event) {
		event.preventDefault();
		$(this).add('#main-nav').toggleClass('active');
		return false;
	});

})( jQuery, window, document );
